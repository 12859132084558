body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Calendar */
.MuiPickerStaticWrapper-content{
  background-color: #00000000 !important;
}

/* Swiper */
.swiper-pagination {
  bottom: 94% !important;
}

.swiper-pagination-bullet {
  background: #fff !important;
}

.swiper-pagination-bullet-active {
  background: #FCAE1A !important;
}

.editSwiper .swiper-pagination {
  pointer-events: none !important;
}

/* Filepond */
.filepond--panel-root {
  background-color: #00000000 !important;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: 5px;
    scrollbar-color: #4b4b4b #00000000;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px;
  }

  *::-webkit-scrollbar-track {
    background: #00000000;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #4b4b4b;
    border-radius: 4px;
    border: 3px solid #4b4b4b;
  }